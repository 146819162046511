@import '../App.scss';

footer {
	padding: 10px;
	background-color: $light-black;
	display: grid;
	grid-template-areas: "sava"
	                      "footer-date";
	font-family: "Monoton", sans-serif;
	color: white;
	p {
		margin-bottom: 3px;
	}
	.sava {
		text-align: center;
		font-size: 22px;
		word-spacing: 5px;
		span {
			grid-area: developed-span;
		}
		a {
			grid-area: sava-a;
			color: white;
			text-decoration: none;
			background-image: linear-gradient(currentColor,currentColor);
			background-position: 0 100%;
			background-repeat: no-repeat;
			background-size: 0 1px;
			transition: background-size .3s;
			&:hover {
				background-size: 100% 1px;
			}
		}
	}
	.footer-date {
		font-size: 22px;
		text-align: center;
		grid-area: footer-date;
	}
}