@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

// Devices
$macro: 2000px;
$desktop-big: 1500px;
$desktop: 1300px;
$schedule: 1200px;
$schedule-2: 1105px;
$principles: 960px;
$imgs: 945px;
$principles-2: 840px;
$tablet: 768px;
$map-1: 640px;
$map-2: 540px;
$popAskCard: 520px;
$phone: 470px;
$schedule-3:450px;
$map-3: 440px;
$popAskCard-2: 390px;
$micro: 350px;
$popAskCard-3: 340px;
$super-micro: 325px;


// Colors
$red: #d50000;
$light-red: rgba(221,4,4,.52);
$blue: #0000b8;
$light-blue: blue;
$pop-ask-light-blue: #197bfd;
$pop-ask-blue: #0052bf;
$gray: #8e8e8e;
$light-gray: #5c5c5c;
$digit-gray: #b3b3b3;
$smoke: rgb(229, 229, 229);
$white: white;
$light-black: #1a1a1a;

body {
	background-color: red;
}

