@import "src/App.scss";

.tabs {
    z-index: 1100 !important;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: #c6c6c6;
    display: grid;
    margin: 15px 0 20px;
    justify-content: center;
    ul {
        z-index: 100;
        margin: 0;
        padding: 0;
    }
    li {
        display: inline-block;
        cursor: pointer;
        transition-duration: 0.2s;
        padding: 0px 15px;
        margin: 3px;
        border-bottom: 3px solid #c6c6c6;
        &:hover {
            color:white;
            border-color: white;
        }
    }
    .currentTab {
        cursor: default;
        color:white;
        border-color: white;
    }
}

@media screen and (max-width: $phone) {
    .tabs {
        justify-content: center;
        font-size: 20px;
        li {
            padding: 0 5px;
        }
    }
}

@media screen and (max-width: $super-micro) {
    .tabs {
        justify-content: center;
        font-size: 18px !important;
        li {
            padding: 0 5px;
        }
    }
}

@media screen and (max-width: $schedule-3) {
    .principles {
        li {

        }
    }
}