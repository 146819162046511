@import '../../App';

.text-section {
    color: rgb(221, 4, 4);
    font-family: "Roboto", sans-serif;
    display: grid;
    grid-template-areas: "section-title"
                         "section-content";
    .section-title {
        margin-top: 15px;
        margin-bottom: 15px;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 300;
        font-size: 38px;
        display: grid;
        justify-self: center;
        grid-area: section-title;
    }
    .section-content {
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        grid-area: section-content;
    }
}

@media screen and (max-width: $phone) {
    .text-section {
        .section-title {
            text-align: center;
            font-size: 38px;
        }
    }
}
