@import 'src/App.scss';

.schedule-futsal {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.schedule-football {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.schedule-football {
    height: 300px;
}

.schedule {

    ul {
        display: grid;
        background: rgba(255, 255, 255, 0.23);
        border-radius: 13px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.6px);
        -webkit-backdrop-filter: blur(5.6px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 0;
        margin-right: 15px;
        .weekday {
            padding: 10px;
            font-size: 19px;
            font-family: "Roboto", sans-serif;
            display:grid;
            justify-content: center;
            font-weight: 500;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
        .time {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        li {
            display: grid;
            font-family: "Roboto", sans-serif;
            align-content: center;
            justify-content: center;
            list-style-type: none;
            padding: 30px 10px;
            grid-template-areas: 'icon span-content';
            i {
                align-self: center;
                display: grid;
                grid-area: icon;
                margin-right: 5px;
            }
            span {
                align-self: center;
                grid-area: span-content;
            }
        }

    }
}

@media screen and (min-width: $desktop) {
    .schedule {
        ul {
            li {
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: $schedule) {
    .schedule {
        ul {
            li {
                padding: 25px 5px;
            }
        }
    }
}

@media screen and (max-width: $schedule-2) {
    .schedule {
        ul {
           li {
               text-align: center;
               grid-template-areas: "icon"
                                    "span-content";
           }
            .place {
                span {
                    display: grid;
                    justify-self: center;
                }
            }
        }
    }
}

@media screen and (max-width: $imgs) {
    .schedule-football {
        height: 200px;
        ul {

        }
    }
    .schedule {
        justify-content: center;
        grid-template-columns: 1fr;

        grid-template-rows: (7, 1fr);
        ul {
            grid-template-columns: 4fr 2fr 4fr;
            margin: 4px;
            .weekday {
                border-right: 1px solid rgba(255, 255, 255, 0.3);
                border-bottom: none;
                padding: 10px 10px;
                justify-content: start;
            }
            li {
                margin: 0;
                padding: 15px 15px;
                grid-template-areas: "icon span-content";
            }
            .time {
                display: grid;
                justify-self: center;
                border-right: 1px solid rgba(255, 255, 255, 0.3);
                border-bottom: none;
            }
            .place {
               justify-content: start;
            }
        }
    }
}

@media screen and (max-width: $phone) {
    .schedule-football {
        height: 150px;

    }
    .schedule {
        justify-content: right;
        grid-template-columns: 1fr;

        grid-template-rows: (7, 1fr);
        ul {
            grid-template-columns: 4fr 2fr 4fr;
            margin: 4px;
            .weekday {
                font-size: 15px;
                padding: 10px 4px;
                justify-content: start;
            }
            li {
                font-size: 15px !important;
                padding: 10px 6px;

            }

        }
    }
}

@media screen and (max-width: $micro) {
    .schedule {
        justify-content: center;
        ul {

            .weekday {
                padding: 8px 4px;
            }
            li {
                margin: 0px;

                padding: 10px 4px;
            }
            .place {
                font-size: 13px !important;
            }
        }
    }
}

@media screen and (max-width: $super-micro) {
    .schedule {
        justify-content: center;
        ul {
            .weekday {
                padding: 8px 3px;
            }
            li {

                padding: 10px 3px;
            }
            .place {
                font-size: 13px !important;
            }
        }
    }
}