.important {
    position: relative;
    background-color: gray;
    height: 800px;
    .red, .blue {
        position: absolute;
        top: 50px;
        left: 400px;
        width: 500px;
        height: 300px;
    }
    .n {
        opacity: 0;
    }
    .red {

        background-color: red;
    }
    .blue {
        background-color: blue;
    }
}