@import "src/App";

.principles {
	padding: 0;
	list-style-type: none !important;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	justify-self: center;
	li {
		margin: 10px 40px;
		display: grid;
		grid-template-columns: 90px 1fr;
		grid-template-rows: 1fr 1fr;
		grid-template-areas: "principles-digit principles-title"
	                         "principles-digit principles-description";
		.principles-digit {
			display: grid;
			justify-self: center;
			padding-right: 15px;
			color: $digit-gray;
			font-size: 120px;
			font-weight: 700;
			font-family: "Anton", sans-serif;
			grid-area: principles-digit;
		}
		.principles-title {
			display: grid;
			align-self: end;
			font-family: "Roboto Condensed", sans-serif;
			font-size: 35px;
			font-weight: 800;

			grid-area: principles-title;
		}
		.principles-description {
			padding-left: 10px;
			font-family: "Roboto", sans-serif;
			font-weight: 300;
			grid-area: principles-description;
		}

	}
}

@media screen and (max-width: $principles) {
	.principles {
		grid-template-columns: repeat(1, 1fr);
		li {
			margin: 10px 10px;
		}
	}
}

@media screen and (max-width: $principles-2) {
	.principles {
		li {
			margin: 10px 10px;
		}
	}
}

@media screen and (max-width: $schedule-3) {
	.principles {
		li {
			.principles-digit {
				padding-right: 15px;
				font-size: 100px;
			}
			.principles-title {
				font-size: 27px;
			}
			.principles-description {
				padding: 0;
				font-size: 16px;
			}
		}
	}
}

@media screen and (max-width: $micro) {
	.principles {
		li {
			.principles-digit {
				padding-right: 15px;
				font-size: 80px;
			}
			.principles-title {
				font-size: 20px;
			}
			.principles-description {
				padding: 0;
				font-size: 14px;
			}
		}
	}
}