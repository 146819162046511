@import "src/App.scss";

.map {
    background: rgba(255, 255, 255, 0.23);
    border-radius: 13px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.6px);
    -webkit-backdrop-filter: blur(5.6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 12px;
    width: 1100px;
    display: grid;
    iframe {
        border: 0;
        margin: 0;
        border-radius: 5px;
    }
}

@media screen and (max-width: $desktop-big){
    .map {
        width: 1110px;
    }
}

@media screen and (max-width: $schedule){
    .map {
        width: 950px;
    }
}

@media screen and (max-width: $schedule-2){
    .map {
        width: 850px;
    }
}

@media screen and (max-width: $principles){
    .map {
        width: 750px;
    }
}

@media screen and (max-width: $principles-2){
    .map {
        width: 700px;
    }
}

@media screen and (max-width: $tablet){
    .map {
        width: 600px;
    }
}

@media screen and (max-width: $map-1){
    .map {
        width: 500px;
    }
}

@media screen and (max-width: $map-2){
    .map {
        width: 400px;
    }
}

@media screen and (max-width: $map-3){
    .map {
        width: 100%;
    }
}


