@import "src/App.scss";

.text-image-section {
    position: relative;
    padding-bottom: 5px;
    color: white;
    display: grid;
    grid-template-areas: "section-title"
                       "section-content";

    h1, h2 {
        margin-top: 15px;
        margin-bottom: 15px;
        z-index: 100;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 38px;
        font-weight: 300;
        grid-area: section-title;
        text-align: center;
    }

}
.futsal-section {
    background:
            linear-gradient(rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.42)),
            url("../../assets/img/backgroundFutsal.jpg") center/cover;
}

.football-section {
    background:
        linear-gradient(rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.42)),
        url("../../assets/img/backgroundFootball.jpg") center/cover;
}

