@import '../App.scss';
@import './Icons/Icons.scss';


header {
    color: white;
    z-index: 100;
    background-color: $red;
    display: grid;
    grid-template-columns: max-content 1fr 1fr;
    grid-template-areas: "main-header phone icons"
                          "menu menu menu";
    padding-bottom: 8px;
    .phone {
        color: gray;
        grid-area: phone;
        align-self: center;
        justify-self: right;
    }
    .mobile-phone {
        color: white;
        text-decoration: none;
        display: none;
        &:hover {
            color: white;
        }
    }
    .a-mobile-phone {
        color: white;
        text-decoration: none;
    } 
    .main-header {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "logo text-main-header"
                             "logo text-main-header";

        img {
            display: grid;
            grid-area: logo;
            width: 130px;
            border-radius: 50%;
            height: 130px;
        }
        .text-main-header {
            display: grid;
            align-content: center;
            grid-area: text-main-header;
            .page-title {
                color: white;
                font-family: "Roboto", sans-serif;
                font-weight: 300;
                text-decoration: none;
                font-size: 35px;
                display: block;
            }
            span {
                font-size: 13px;
                display: block;
            }
        }
    }
    @media screen and (max-width: $tablet) {
        .main-header {
            img {
                width: 100px;
                height: 100px;
            }
            .text-main-header {
                span {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    header {
        padding-bottom: 0;
        grid-template-columns: 60px  1fr max-content;
        grid-template-areas: "menu main-header mobile-phone";
        .main-header {
            justify-self: center;
        }
        .mobile-phone {
            margin-bottom: 10px;
            align-self: center;
            display: grid;
            margin-right: 25px;
            grid-area: mobile-phone;
            display: block;
        }
    }
}

@media screen and (max-width: $phone) {
    header {
        .mobile-phone {
            margin-right: 10px;
        }
    }
}

@media screen and (max-width: $micro) {
    header {
        .main-header {
            display: grid;
            justify-items: left;
            img {
                width: 80px;
                height: 80px;
            }
        }
        .mobile-phone {
            font-size: 40px;
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: $super-micro) {
    header {
        .main-header {
            display: grid;
            justify-self: left !important;
            img {
                width: 80px;
                height: 80px;
            }
        }
        .mobile-phone {
            font-size: 40px;
            margin-left: 3px;
            margin-right: 3px;
        }
    }
}