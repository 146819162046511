@import "../../App";

.pop-ask-button {
    z-index: 100000;
    background-color: $pop-ask-light-blue;
    position: fixed;
    left: 5px;
    bottom: 5px;
    color: white;
    font-size: 18px;
    padding: 12px;
    border-radius: 10px;
    font-family: "Roboto Condensed", sans-serif;
}
.pop-ask-button:hover {
    cursor: pointer;
    background-color: $pop-ask-blue;
}

@media screen and (max-width: $phone){
    .pop-ask-button {
        padding: 8px;
        font-size: 17px;
    }
}