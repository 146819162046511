@import "src/App.scss";

.op0 {
	opacity: 0;
}

.pop-ask-card-darker {
	position: fixed;
	display: grid;
	align-items: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.53);
	z-index: 100000000000;
	.pop-ask-card {
		position: relative;
		justify-self: center;
		color: white;
		background: rgba(255, 255, 255, 0.39);
		border-radius: 16px;
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(13.2px);
		-webkit-backdrop-filter: blur(13.2px);
		border: 1px solid rgba(255, 255, 255, 0.3);
		display: grid;
		grid-template-areas: "pop-ask-card-main-text"
	                         "pop-ask-card-top"
		                     "pop-ask-card-bottom";
		grid-row-gap: 25px;
		.times {
			cursor: pointer;
			position: absolute;
			top: 20px;
			right: 15px;
			display: block;
			width: 20px;
			height: 2px;
			&::after, &::before {
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -14px;
				margin-top: -1px;
				background-color: #fff;
				width: 26px;
				height: 2px;
				display: block;
				content: '';
				transition: 0.3s;

			}
			&::before {
				transform: rotate(45deg);
			}
			&::after {
				transform: rotate(-45deg);
			}
		}
		.success {
			p {
				text-align: center;
				margin-bottom: 6px;
			}
			font-family: "Roboto Condensed", sans-serif;
			color: #2eff64;
			font-weight: 500;
			font-size: 25px;
			position: absolute;
			top: 50%;
			margin-top: -140px;
			left: 50%;
			margin-left: -170px;
			grid-area: success-card;
			display: block;
			width: 350px;
			height: 200px;
			i {
				text-align: center;
				display: block;
				margin: 25px auto;
				width: 100px;
				display: block;
			}
			span {
				text-align: center;
				display: block;
				font-weight: normal;
				font-size: 17px;
			}
		}

		.pop-ask-card-main-text {
			text-align: center;
			padding-top: 30px;
			font-weight: 300;
			font-family: "Roboto", sans-serif;
			font-size: 30px;
			margin: 0;
			justify-self: center;
			grid-area: pop-ask-card-main-text;
		}
		.pop-ask-card-top {
			padding: 20px;
			display: grid;
			grid-area: pop-ask-card-top;
			.pop-ask-card-top-text {
				text-align: center;
				font-weight: 300;
				font-family: "Roboto", sans-serif;
				font-size: 22px;
			}
			.pop-ask-card-form {
				display: grid;
				grid-template-columns: 1fr;
				.pop-input {
					justify-content: center;
					margin-bottom: 8px;
					display: grid;
					grid-template-areas: "pop-icon pop-input";
					grid-template-columns: 35px 1fr;
					i {
						border-radius: 5px 0 0 5px;
						text-align: center;
						padding: 5px;
						background-color: #197bfd;
						color: white;
						display: block;
						grid-area: pop-icon;
					}
					input {
						font-family: "Roboto Condensed", sans-serif;
						background-color: white;
						padding: 5px;
						border-radius: 0 5px 5px 0;
						border: none;
						outline: none;
						grid-area: pop-input;
					}
				}
				.pop-text-area {
					justify-content: center;
					margin-bottom: 8px;
					display: grid;
					grid-template-areas: "pop-icon pop-area";
					grid-template-columns: 35px 1fr;
					i {
						border-radius: 5px 0 0 5px;
						text-align: center;
						align-content: center;
						padding: 5px;
						background-color: #197bfd;
						color: white;
						display: grid;
						grid-area: pop-icon;
					}
					textarea {
						font-family: "Roboto Condensed", sans-serif;
						background-color: white;
						resize: none;
						padding: 5px;
						height: 100px;
						border-radius: 0 5px 5px 0;
						border: none;
						outline: none;
						grid-area: pop-area;
					}
				}
				button {
					font-weight: 300;
					font-family: "Roboto", sans-serif;
					font-size: 19px;
					border: 0;
					padding: 10px;
					border-radius: 5px;
					background-color: #197bfd;
					color: white;
					transition-duration: 0.2s;
					&:hover {
						background-color: $pop-ask-blue;
					}
				}
			}
		}
		.pop-ask-card-bottom {
			padding: 20px;
			display: grid;

			grid-template-areas: "pop-ask-card-bottom-text"
		                         "pop-ask-card-social";
			grid-area: pop-ask-card-bottom;
			border-top: 2px solid white;
			.pop-ask-card-bottom-text {
				text-align: center;
				font-weight: 300;
				font-family: "Roboto", sans-serif;
				font-size: 22px;
				grid-area: pop-ask-card-bottom-text;
			}
			.pop-ask-card-social {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				justify-content: center;
				grid-area: pop-ask-card-social;
				a {
					font-size: 45px;
					width: 80px;
					height: 80px;
					border-radius: 100%;
					text-decoration: none;
					background-color: #197bfd;
					color: white;
					display: grid;
					justify-self: center;
					justify-content: center;
					align-content: center;
					padding: 0px;
					transition-duration: 0.2s;
					&:hover {
						background-color: $pop-ask-blue;
					}
				}
			}
		}
	}
}


@media screen and (max-width: $phone) {
	.pop-ask-card-darker {

		.pop-ask-card {
			grid-row-gap: 8px;
			.pop-ask-card-main-text {

				font-size: 25px;
				margin-top: 20px;
			}
			.success {
				font-size: 22px;

			}
			.pop-ask-card-top {
				padding: 10px;
				.pop-ask-card-top-text {
					font-size: 18px;
				}
				.pop-ask-card-form {
					button {
						font-size: 18px;
					}
				}
			}
			.pop-ask-card-bottom {
				padding: 10px;
				.pop-ask-card-bottom-text {
					font-size: 18px;
				}
				.pop-ask-card-social {
					a {
						width: 70px;
						height: 70px;
						font-size: 35px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $micro) {
	.pop-ask-card-darker {
		.pop-ask-card {
			.times {
				top: 20px;
				left: 50%;
			}
			.success {
				top: 150px;
				left: 0;
				margin: 0 auto;
				width: 100%;
				font-size: 18px;
				p {
					text-align: center;
				}
				span {
					text-align: center;
				}
				i {
					text-align: center;
				}
			}
			.pop-ask-card-bottom {

				.pop-ask-card-bottom-text {
					font-size: 18px;
				}
			}
		}
	}
}
