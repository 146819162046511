@import '../../App.scss';

.desktop-icons {
    display: grid;
    grid-area: icons;
    list-style-type: none;
    grid-template-columns: repeat(4, 100px);
    justify-content: end;
    align-content: center;
    li {
        a {
            color: #dcdcdc;
        }
        a:hover {
            color: white;
        }
        color: white;
        display: inline-block;
        margin-right: 50px;
    }

    @media screen and (max-width: $tablet) {
        display: none;    
    }
}

.mobile-icons {
    position: absolute;
    bottom: 0;
    left: 20px;
    list-style-type: none;
    display: block;
    li {
        margin-top: 30px;
    }
    @media screen and (min-width: $tablet) {
        display: none;
    }
}

@media screen and (max-width: $tablet) {
    .fa-volume-control-phone {
        display: none;
    }
}

@media screen and (max-width: $phone) {
    .mobile-icons {
        margin: 0;
        li {
            padding: 0;
            margin-top: 10px;
        }
    }

}
