.invisible {
    display: none;
}
.pop-registration-darker {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.56);
    .pop-registration {
        padding: 8px;
        position: fixed;
        width: 100%;
        top:30%;
        background-color: white;
        border: 1px solid gray;
        .fa-times {
            font-size: 25px;
            position: absolute;
            top: 5px;
            right: 12px;
            &:hover {
                color: blue;
            }
        }
        .main-text-registration {
            color: red;
            font-weight: bold;
            font-size: 26px;
            text-align: center;
        }
        .sub-text-registration {
            color: gray;
            font-size: 21px;
            text-align: center;
        }
        form {
            padding: 20px;
            margin: 0 auto;
            width: 300px;
            .name-field, .phone-field {
                .icon-field {
                    position: relative;
                    width: 30px;
                    text-align: center;
                    display: inline-block;
                    i {
                        font-size: 25px;
                        position: absolute;
                        left: 5px;
                        bottom: -7px;
                    }
                }
                border: 1px solid black;
                margin-top: 10px;
                width: 250px;
                height: 45px;
                background-color: red;
            }
            input {
                padding-left: 8px;
                width: 218px;
                border: 0;
                height: 100%;
                display: inline-block
            }
            button {
                width: 250px;
                margin-top: 20px;
            }
        }
    }
}