@import '../../App.scss';

.menu {
    display: grid;
    grid-area: menu;
    justify-self: center;
    align-self: center;
    nav {
        grid-area: nav;
        display: grid;
        width: 600px;
        grid-template-columns: auto auto auto auto;
        justify-content: stretch;
        a {
            cursor: pointer;
            display: grid;
            justify-self: center;
            color: $smoke; 
            text-decoration: none;
            background-image: linear-gradient(currentColor,currentColor);
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 0 1px;
            color: #fff;
            transition: background-size .3s;
        }
        .active-link {
            background-size: 100% 1px;
        }
        a:hover {
            background-size: 100% 1px;
        }
    }
    @media screen and (max-width: $tablet) {
        nav {
            display: none;
        }
    }
    @media screen and (max-width: $tablet) {
        .burger {
            margin-left: 20px;
            z-index: 10000000;
            display: block;
            width: 50px;
            height: 50px;
            position: absolute;
            transition: 0.3s;
            top: 30px;
            left: 10px;
            span {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -14px;
                margin-top: -1px;
                background-color: #fff;
                width: 28px;
                height: 2px;
                display: block;
                content: '';
                &::after, &::before {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -14px;
                    margin-top: -1px;
                    background-color: #fff;
                    width: 28px;
                    height: 2px;
                    display: block;
                    content: '';
                    transition-duration: 0.3s;

                }
                &::before {
                    transform: translateY(7px);
                }
                &::after {
                    transform: translateY(-7px);
                }
            }
        }
        .active-burger {
            z-index: 100000;
            display: block;
            transform: translateX(45px);
            width: 50px;
            height: 50px;
            position: fixed;
            top: 30px;
            left: 10px;
            transition: 0.3s;
            span {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -14px;
                margin-top: -1px;
                background-color: #fff;
                width: 28px;
                height: 0px;
                display: block;
                content: '';
                &::after, &::before {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -14px;
                    margin-top: -1px;
                    background-color: #fff;
                    width: 26px;
                    height: 2px;
                    display: block;
                    content: '';
                    transition: 0.3s;

                }
                &::before {
                    transform: rotate(45deg);
                }
                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    @media screen and (max-width: $tablet) {
        .default {
            display: none;
        }
        nav.inactive-nav {
            //animation: slideOutLeft;
            padding-top: 80px;
            text-align: center;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 150px;
            background-color: red;
            a {
                display: block;
                margin: 40px 0;
            }
        }
        nav.active-nav {
            //animation: slideInLeft;
            animation-duration: 0.3s;
            padding-top: 80px;
            text-align: center;
            z-index: 10000;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 150px;
            background-color: $red;
            display: block;
            a {
                display: block;
                margin: 40px 0;
            }
        }    
    }
    
}

@media screen and (max-width: $micro) {
    .menu {
        .burger {
            top: 15px;
            left: -10px;
        }
        .active-burger {
            top: 15px;

        }
    }

}