@import '../App.scss';

.invitation {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: max-content;
    grid-template-areas: "images images images";
    .image {
        background-size: cover;
        background-position: 50% 50%;
        max-width: 100%;
        height: 400px;
    }
    .dark-section {
        font-family: "Roboto", sans-serif;
        font-size: 45px;
        font-weight: 100;
        display: grid;
        align-content: center;
        text-align: center;
        height: 60px;
        color: white;
        background: rgba(67, 67, 67, 0.39);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(13.2px);
        -webkit-backdrop-filter: blur(13.2px);
        //background-color: rgba(0, 0, 0, 0.62);
        position: absolute;
        width: 100%;

    }
    a {
        padding-top: 18px;
        text-decoration: none;
        display: block;
        text-align: center;
        border-radius: 30px;
        width: 120px;
        height: 60px;
        background-color: red;
        position: fixed;
        bottom: 10px;
        right: 20px;
        margin-left: -85px;
    }
    .image1 { background-image: url("../assets/img/poigratVminifutbol.jpg");}
    .image2 {
        background-image: url("../assets/img/poigratVminifutbolIfutbol.jpeg");
        display: grid;
        grid-template-columns: 1fr 1fr;
        .photo-button-section, .video-button-section {
            display: none;
            align-content: end;
            justify-content: center;
            bottom: 10px;
            height: 100%;
            padding-bottom: 5px;
            .video-button, .camera-button {
                color: white;
                border-radius:100%;
                background-color: gray;
                border: 1px solid black;
                padding: 8px;
                .fa {
                    font-size: 20px;
                }
                span {
                    margin-left: 5px;
                    display: inline-block;
                }
                @media only screen and (max-width: 768px) {
                    .fa {
                        font-size: 30px;
                    }
                    span{
                        display: none;
                    }
                }
            }
            @media only screen and (min-width: 768px) {
                .video-button, .camera-button {
                    padding: 10px 23px;
                    background-color: red;
                    border-radius: 5px;
                }
            }

        }
        
    }
    .image3 { background-image: url("../assets/img/poigratVfutbol.jpg");}
}

@media screen and (max-width: $tablet) {
    .invitation {
        .dark-section {
            font-size: 40px;
        }
    }
}

@media screen and (max-width: $tablet) {
    .invitation {
        .dark-section {
            font-size: 30px;
        }
    }
}

@media screen and (max-width: $micro) {
    .invitation {
        .dark-section {
            font-size: 25px;
        }
    }
}

