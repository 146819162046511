@import './src/App';

.tabsContent {
    display: grid;
    justify-self: center;
    z-index: 100;
    display: grid;
    font-family: "Roboto";
    //min-width: 900px;
    min-height: 485px;
    justify-content: center;
    align-content: center;
    padding: 0 15px;
    ul {
        z-index: 100;
        list-style-type: disc;
    }
    .text-image {

        z-index: 100;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr max-content;
        grid-template-areas: "content-text content-image";
        .content-text {

            display: grid;
            padding-right: 25px;
            grid-area: content-text;
            li {
                margin-top: 8px;
            }
        }
        .content-image {

            //background: rgba(255, 255, 255, 0.23);
            //border-radius: 13px;
            //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            //backdrop-filter: blur(5.6px);
            //-webkit-backdrop-filter: blur(5.6px);
            //border: 1px solid rgba(255, 255, 255, 0.3);
            display: grid;
            justify-content: right;
            img {
                border: 3px solid rgba(255, 255, 255, 0.3);
                border-radius: 15px;
                height: 350px;
                object-fit: cover;
                width: 100%;
            }
        }
        .futsal {
            background-image: url("../../../assets/img/futsal.jpg");
        }
        .futbol {
            background-image: url("../../../assets/img/futbol.jpg");
        }
    }
}
.important-section {
    .tabs {
        color: $light-red;
        li {
            border-color: $light-red;
            &:hover {
                color: $red;
                border-color: $red;
            }
        }
        .currentTab {
            cursor: default;
            color: $red;
            border-color: $red;
        }
    }
}

@media screen and (max-width: $macro) {
    .tabsContent {
        justify-self: left;
        .text-image {
            display: grid;
            justify-items: flex-start;
            grid-template-columns: 1fr 500px;
            grid-template-areas: "content-text content-image";
            .content-text {
                display: grid;
                padding-right: 25px;
                grid-area: content-text;
                li {
                    margin-top: 8px;
                }
            }
            .content-image {
                width: 100%;
                display: grid;
                justify-content: right;
                img {
                    height: 350px;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: $desktop-big) {
    .tabsContent {
        .text-image {
            .content-image {
                img {
                    height: 450px;
                }
            }
        }
    }
}

@media screen and (max-width: $desktop) {
    .tabsContent {
        .text-image {
            grid-template-columns: 1fr 1fr;
            .content-image {
                img {
                    object-fit: cover;
                    height: 350px;
                }
            }
        }
    }
}


@media screen and (max-width: $imgs) {
    .tabsContent {
        .text-image {
            grid-template-columns: 1fr;
            grid-template-areas: "content-text"
                                 "content-image";
            .content-image {
                justify-self: center;
            }
        }
    }
}

@media screen and (max-width: $phone) {
    .tabsContent {
        padding: 0 5px;
        .text-image {
            .content-text {
                padding-right: 0;
            }
            .content-image {
                img {
                    height: 230px;
                }
            }
        }
    }
}