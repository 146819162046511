@import "../../App";

.pop-registration-button {
    z-index: 1000000;
    text-decoration: none;
    background-color: #00a707;
    position: fixed;
    right: 5px;
    bottom: 5px;
    font-size: 18px;
    padding: 12px;
    border-radius: 10px;
    color: white;
    font-family: "Roboto Condensed", sans-serif;
}
.pop-registration-button:hover {
    background-color: #007b06;
    color: white;
}

@media screen and (max-width: $phone){
     .pop-registration-button {
         padding: 8px;
         font-size: 17px;
     }
}
